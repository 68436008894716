/* eslint-disable react/jsx-newline */
import * as React from 'react'

import imgPlanif from '@assets/images/backgrounds/staffea-sante-soignant.png'
import { Box, Center, Flex, Heading, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { HorizontalCard } from '@components/ui/Card'
import { HorizontalCarousel } from '@components/ui/Carousel'
import IconItemList from '@components/ui/IconItemList'
import Link from '@components/ui/Link'
import MenuSticky from '@components/ui/MenuSticky'
import { functionalities, horizontalCarouselContent, mission } from '@data/ehpad/lp/planning-aide-soignant'
import { device } from '@helpers/mediaQueryBreakpoints'
import { graphql, navigate } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

const NurseAidPlanningPage = () => {
    const [isMobile] = useMediaQuery(device.xs)
    const [isTablet] = useMediaQuery('(min-width: 1000px)')

    return (
        <>
            <Seo
                title="Planning Aide-Soignant.e Ehpad"
                description="Découvrez Staffea pour gérer facilement votre planning d'aide-soignant en Ehpad ➜ ✓ Appli mobile ✓ Prise en main rapide ✓ Gestion et partage en temps réel"
            />

            <main>
                <MenuSticky>
                    <Section
                        bg="indigo.100"
                        id="nursing-aid-planning-staffea-section"
                        name="staffea"
                        // footer={
                        //     <Center>
                        //         <Flex
                        //             bg="white"
                        //             borderRadius="50%"
                        //             w="60px"
                        //             h="60px"
                        //             align="center"
                        //             justify="center"
                        //             position="absolute"
                        //         >
                        //             <Icon as={IoIosArrowDown} color="indigo.500" w="8" h="8" />
                        //         </Flex>
                        //     </Center>
                        // }
                        backgroundImage={isTablet ? imgPlanif : undefined}
                        backgroundPosition="right top"
                        backgroundRepeat="no-repeat"
                        backgroundSize="contain"
                        aria-label="Homme aide-soignant utilise l'application web Staffea"
                    >
                        <Flex direction="column" align="start" textAlign="left" maxW="850" py={20}>
                            <Heading
                                as="h1"
                                mb={10}
                                fontWeight="bold"
                                color="indigo.500"
                                maxW={isTablet ? '80%' : '100%'}
                            >
                                Logiciel de planning pour aide-soignant en Ehpad
                            </Heading>

                            <Text color="indigo.500" mb="12" maxW={isTablet ? '65%' : '650'}>
                                Restez focus sur votre métier : Staffea facilite votre quotidien. Grâce à
                                l&#39;application mobile, passez moins de temps à gérer votre planning en Ehpad et
                                libérez votre potentiel. Mis à jour automatiquement, votre planning vous permet
                                également de demander des ajustements à tout moment. Améliorez dès maintenant votre
                                qualité de vie au travail !
                            </Text>

                            <Center alignSelf={!isTablet ? 'center' : 'inherit'}>
                                <CtaButton
                                    text="Demander une démo"
                                    description="Démonstration de 30 minutes en visioconférence"
                                    onClick={() => navigate('mailto:contact@staffea.com')}
                                />
                            </Center>
                        </Flex>
                    </Section>

                    <Section
                        bg="white"
                        id="nursing-aid-planning-features-section"
                        name="fonctionnalités"
                        footer={
                            <Section bg="indigo.100">
                                <Box py={20}>
                                    <Center textAlign="center">
                                        <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" mb={10}>
                                            Une solution pour toutes les professions en Ehpad
                                        </Text>
                                    </Center>
                                    <Text
                                        fontSize="md"
                                        color="indigo.500"
                                        style={{ columnCount: isMobile ? 1 : 2, columnGap: '30px' }}
                                    >
                                        La plateforme Staffea répond parfaitement aux contraintes de votre planning
                                        d&#39;aide-soignant en Ehpad. Les modifications d&#39;horaires ou les échanges
                                        n&#39;ont jamais été aussi faciles à demander et à obtenir ! Staffea rend votre
                                        quotidien plus agréable et plus efficace. Cette plateforme est également conçue
                                        pour s&#39;adapter aux&nbsp;
                                        <Link
                                            to="/ehpad/lp/planning-infirmier"
                                            variant="underlineBold"
                                            _hover={{ color: 'green.500' }}
                                        >
                                            plannings des infirmiers en Ehpad
                                        </Link>
                                        &nbsp;et aux&nbsp;
                                        <Link
                                            to="/ehpad/lp/planning-ash"
                                            variant="underlineBold"
                                            _hover={{ color: 'green.500' }}
                                        >
                                            plannings des ASH en Ehpad.
                                        </Link>
                                        &nbsp;Grâce à Staffea, c&#39;est tout le personnel médical qui gagne du temps,
                                        diminue sa charge mentale et améliore son bien-être professionnel !
                                    </Text>
                                </Box>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                Des fonctionnalités astucieuses
                                <Text as="span" color="green.500" display="block">
                                    pour simplifier la gestion de votre planning.
                                </Text>
                            </Text>

                            <Flex my="10" gap="20" direction="column" wrap="wrap" mx="auto">
                                {functionalities.map(({ id, subTitle, title, image, list }) => (
                                    <Box key={id}>
                                        <HorizontalCard
                                            w={['xs', 'sm', '2xl', '3xl', '4xl']}
                                            mediaW={['xs', 'sm', 'md']}
                                            contentW={['xs', 'sm', 'md']}
                                            mediaFlexBasis={['50%', '50%', '50%', '70%']}
                                            isReverse={id % 2 === 0}
                                            gap={['4', '8']}
                                            hasCenteredContent={true}
                                            media={
                                                <Image
                                                    src={image}
                                                    objectFit="cover"
                                                    maxW="60"
                                                    bgColor={'indigo.100'}
                                                    px={30}
                                                />
                                            }
                                            title={
                                                <Box>
                                                    <Text fontSize="sm" fontWeight="bold" color="green.500">
                                                        {subTitle}
                                                    </Text>

                                                    <Text as="h3" fontWeight="bold" color="indigo.500" mb="4">
                                                        {title}
                                                    </Text>
                                                </Box>
                                            }
                                            content={<IconItemList list={list} />}
                                        />
                                    </Box>
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500" pt={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour optimiser votre quotidien d&nbsp;aide-soignant.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ header, title, text, image, alt }) => {
                                    return {
                                        title: header,
                                        description: (
                                            <>
                                                <Text color="indigo.500" fontSize="xs" align="start" mb="4">
                                                    {title}
                                                </Text>

                                                <Text color="indigo.500" fontSize="xs" align="start">
                                                    {text}
                                                </Text>
                                            </>
                                        ),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="nursing-aid-planning-process-section" name="démarche">
                        <Box py={20}>
                            <Center>
                                <Text as="h2" fontSize="xl" fontWeight="bold" color="indigo.500">
                                    Au cœur de la démarche de Staffea :&nbsp;
                                    <Text as="span" color="green.500">
                                        la qualité de vie au travail.
                                    </Text>
                                </Text>
                            </Center>

                            <Flex gap="14" direction="row" wrap="wrap" mt="8" justify="center">
                                {mission.map(({ id, title, content, svg }) => (
                                    <Flex key={id} direction="column" maxW="290px">
                                        <Image src={svg} maxW="250px" objectFit="cover" mb="2" />

                                        <Text as="h3" color="green.500" fontSize="sm" fontWeight="bold" mb="4">
                                            {title}
                                        </Text>

                                        {content.map((parahraph) => (
                                            <Text key={uuidv4()} color="indigo.500" fontSize="xs" mb="4">
                                                {parahraph}
                                            </Text>
                                        ))}
                                    </Flex>
                                ))}
                            </Flex>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default NurseAidPlanningPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["ehpad", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
