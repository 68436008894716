import imgDedicate from '@assets/images/img-dedicate-100.png'
import imgFeedback from '@assets/images/img-feedback.png'
import imgUser from '@assets/images/img-handle-all-optimize-experience.png'
import imgIntuitive from '@assets/images/img-intuitive-interface.png'

const content = [
    {
        id: 1,
        header: "Gérez tout depuis l'application mobile",
        title: 'Contrôlez facilement votre planning !',
        text: "Simplifiez votre gestion de planning en vous aidant de Staffea. Vous pouvez demander un ajustement de vos horaires, des échanges et des congés en un clic sur l'application mobile. Vous pouvez également télécharger vos arrêts maladies dématérialisés, ou encore signer électroniquement votre contrat de travail.",
        image: imgUser,
        alt: 'Staffea, application mobile multifonctionnelle',
    },
    {
        id: 2,
        header: 'Accédez à une interface intuitive et ludique',
        title: 'Un plaisir à utiliser !',
        text: "Grâce à son interface ludique et ergonomique, vous aurez plaisir à utiliser Staffea au quotidien. Consultez votre planning et demandez des changements où et quand vous voulez, depuis votre smartphone. Elle est extrêmement facile à prendre en main ! Elle deviendra vite votre meilleure alliée dans votre quotidien d'aide soignant.",
        image: imgIntuitive,
        alt: 'Staffea, application mobile écrans de gestion des planning et disponibilités',
    },
    {
        id: 3,
        header: 'Consacrez-vous à 100% à votre métier',
        title: 'Vous êtes aide-soignant, pas gestionnaire de planning.',
        text: "Gagnez du temps dans votre journée en confiant votre planning à Staffea. Plus besoin de réfléchir à vos horaires, à vos congés ou à vos remplacements. L'application intelligente libère votre charge mentale ! Sollicitez des modifications en cas de besoin et recevez un retour de votre gestionnaire simplement. Concentrez-vous uniquement sur votre travail !",
        image: imgDedicate,
        alt: 'Staffea, application mobile écrans de demande de congés et échange entre employés',
    },
    {
        id: 4,
        header: 'Transmettez directement vos feedbacks',
        title: 'Partagez votre expérience à votre Ehpad.',
        text: "Après une mission d'intérim ou une semaine de travail classique en Ehpad, utilisez Staffea pour partager votre ressenti, grâce au système de feedback intégré. Indiquez les éventuels événements indésirables qui se sont produits et faites part de votre expérience à votre hiérarchie, pour améliorer votre qualité de vie au travail.",
        image: imgFeedback,
        alt: "Staffea, application mobile écran d'édition de journée",
    },
]

export default content
